import { render, staticRenderFns } from "./VueBlock.vue?vue&type=template&id=1e2d4392&scoped=true&"
import script from "./VueBlock.vue?vue&type=script&lang=js&"
export * from "./VueBlock.vue?vue&type=script&lang=js&"
import style0 from "./VueBlock.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VueBlock.vue?vue&type=style&index=1&id=1e2d4392&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e2d4392",
  null
  
)

export default component.exports