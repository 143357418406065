<template>
  <div class="usage-card">
    <h5 class="mb-0">
      Quotas
    </h5>
    <div
      v-if="!isOrgUsageLoading"
      class="usage-bar-list"
    >
      <UsageProgressBar
        title="Workbooks Shared"
        :progress="(orgUsage.shared_workbooks / organization.shared_workbooks_limit) * 100"
        :used="orgUsage.shared_workbooks.toString() + '/' + organization.shared_workbooks_limit.toString()"
      />
      <UsageProgressBar
        title="Workbooks"
        :progress="(orgUsage.workbooks / (organization.shared_workbooks_limit * orgMembers.length)) * 100"
        :used="orgUsage.workbooks.toString() + '/' + (organization.shared_workbooks_limit * orgMembers.length).toString()"
      />
      <UsageProgressBar
        title="Members"
        :progress="(orgMembers.length / organization.shared_workbooks_limit) * 100"
        :used="orgMembers.length.toString() + '/' + organization.shared_workbooks_limit.toString()"
      />
    </div>
  </div>
</template>
<script lang="ts">
import DataMaLogicAbstract from "../../../Structure/Mixins/DataMaLogicAbstract.vue";
import OrgAbstract from "../Mixins/OrgAbstract.vue";
import UsageProgressBar from "@/components/Core/Structure/Progress/UsageProgressBar.vue";

export default {
  name: 'UsageCard',
  components: {UsageProgressBar},
  mixins: [
    DataMaLogicAbstract, OrgAbstract
  ],
};
</script>
<style scoped lang="scss">
.usage-bar-list {
  padding-block: 16px;
  width: 100%;
  padding-right: 80px;
}
</style>