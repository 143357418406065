<template>
  <small>
    <v-card
      tile
      elevation="0"
      :loading="userLoading"
    >
      <v-card-title class="">
        <small>
          <v-avatar
            v-if="img_url != null && img_url !== ''"
            left
            size="30"
            class="mr-3"
          >
            <img
              v-if="isValidHttpUrl(img_url)"
              :src="img_url"
              :alt="email"
            >
            <v-icon v-else>{{ img_url }}</v-icon>
          </v-avatar>
          <v-icon
            v-else-if="img_url == null || img_url === ''"
            left
            x-large
          >mdi-account-circle</v-icon>
          <small>&nbsp;&nbsp;{{ name }}</small>
        </small>
        <v-spacer />
        <v-menu
          bottom
          left
          small
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-btn
            class="dataredbg"
            dark
            @click="logout()"
          ><v-icon left>mdi-power</v-icon>Logout</v-btn>
        </v-menu>
      </v-card-title>
    </v-card>
  </small>
</template>
<script>

export default {
  name: "Logout",
  // eslint-disable-next-line vue/require-prop-types
  props: {
    service: {
      type: String,
      default : ""
    },
    data: {
      type: Object,
      default() {
        return null
      }
    },
    checkConnection: {
      type: Function,
      default: null
    },
    mini: {
      type: Boolean,
      default : false
    },
    userLoading: {
      type: Boolean,
      default : false
    },
  },
  data() {
    return {
      email:this.data["email"],
      img_url:this.data["picture"],
      name:this.data["name"]
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    logout(){

      this.$store.dispatch("prep/serviceLogout", {
        params: 
          {
            service:this.service
          } 
        }).then(() => {
          if (typeof this.checkConnection === "function") {
            this.checkConnection()
          }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/theme/config/_colors.scss";

.dataredbg{
  background-color:$datared !important;
  color:white;
}
</style>